.about{
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 120px;
    animation: load 1s ease-in-out; 
    overflow-x: hidden;
}
.couverturePic{
    width: 100vw;
}
.aboutContain{
    margin-top: 150px;
    width: 80vw;
    margin-left: 10vw;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px; 
}
.about>h2 {
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 22px;
    letter-spacing: 1Px;
    color: #02539F;
    margin-top: 100px;
}
.about>h4 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 20px;
    letter-spacing: 2Px;
    color: #02539F;
    margin-top: 100px;
}
.aboutContain > div{
    width: 35vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.aboutContain > div > h3 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 22px;
    letter-spacing: 2Px;
    color: #02539F;
}
.aboutContain > div > p {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 28px;
}
.aboutPic{
    width: 40vw;
    margin-left: 5vw;
    border-radius: 4px;
    box-shadow: 1px 1px 8px 1px rgba(104, 104, 104, 0.75);
}
.aboutServices{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 20px 5% 20px 5%;
}
.aboutServices>div{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
}
.aboutServices > div > h4 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 18px;
    letter-spacing: 2Px;
    color: #f57c00;
}
.aboutServices > div > p {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 24px;
}
.aboutServicePic{
    width: 150px;
    border-radius: 50%;
    box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.415);
}

.aboutContain2 > div{
    margin-top: 50px;
    width: 80vw;
    margin-left: 10vw;
    background-color: #f57b0037;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.aboutContain2 > div > h3 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 22px;
    letter-spacing: 2Px;
    color: #02539F;
    margin-top: -35px;
    background-color: #f57b0037;
}
.aboutContain2 > div > p {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 28px;
}
.aboutDriverPic{

margin-top: -10px;
    width: 30vw;
    margin-left: 60vw;
    border-radius:4px;
}
@media screen and (max-width:1200px) {
    .about{
        width: 100vw;
        height: auto;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 80px;
        animation: load 1s ease-in-out; 
        overflow-x: hidden;
    }
    .couverturePic{
        width: 100vw;
    }
    .aboutContain{
        margin-top: 50px;
        width: 98vw;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px; 
    }
    .about>h2 {
        text-transform: uppercase;
        font-family: 'Poppins';
        font-size: 20px;
        letter-spacing: 1Px;
        color: #02539F;
        margin-top: 100px;
        width: 95vw;
        margin-left: 2.5vw;
    }
    .about>h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 20px;
        letter-spacing: 2Px;
        color: #02539F;
        margin-top: 100px;
    }
    .aboutContain > div{
        width: 45vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .aboutContain > div > h3 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 18px;
        letter-spacing: 1Px;
        color: #02539F;
    }
    .aboutContain > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
    }
    .aboutPic{
        width: 35vw;
        margin-left: 2.5vw;
        border-radius: 4px;
        box-shadow: 1px 1px 8px 1px rgba(104, 104, 104, 0.75);
    }
    .aboutServices{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin: 20px 5% 20px 5%;
    }
    .aboutServices>div{
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 400px;
    }
    .aboutServices > div > h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 16px;
        letter-spacing: 1Px;
        color: #f57c00;
    }
    .aboutServices > div > p {
        font-family: 'Poppins';
        font-size: 12px;
        line-height: 24px;
    }
    .aboutServicePic{
        width: 120px;
        border-radius: 50%;
        box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.415);
    }
    
    .aboutContain2 > div{
        margin-top: 20px;
        width: 90vw;
        margin-left: 2.5vw;
        background-color: #f57b0037;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    .aboutContain2 > div > h3 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 20px;
        letter-spacing: 2Px;
        color: #02539F;
        margin-top: -35px;
        background-color: #f57b0037;
    }
    .aboutContain2 > div > p {
        font-family: 'Poppins';
        font-size: 16px;
        line-height: 26px;
    }
    .aboutDriverPic{
    
    margin-top: -10px;
        width: 35vw;
        margin-right: 30vw;
        border-radius:4px;
    }
}
@media screen and (max-width:700px) {
    .about{
        width: 100vw;
        height: auto;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 80px;
        animation: load 1s ease-in-out; 
        overflow-x: hidden;
    }
    .couverturePic{
        width: 100vw;
    }
    .aboutContain{
        margin-top: 20px;
        width: 98vw;
        margin-left: 0vw;
        border-radius: 4px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 10px; 
    }
    .about>h2 {
        text-transform: uppercase;
        font-family: 'Poppins';
        font-size: 16px;
        letter-spacing: 1Px;
        color: #02539F;
        margin-top: 50px;
        width: 90vw;
        margin-left: 5vw;
    }
    .about>h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 16px;
        letter-spacing: 1Px;
        color: #02539F;
        margin-top: 100px;
        width: 95vw;
        margin-left: 2.5vw;
    }
    .aboutContain > div{
        width: 90vw;
        margin-left: 2.5vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .aboutContain > div > h3 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 16px;
        letter-spacing: 1Px;
        color: #02539F;
    }
    .aboutContain > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
    }
    .aboutPic{
        width: 80vw;
        margin-left: 0vw;
        border-radius: 4px;
        box-shadow: 1px 1px 8px 1px rgba(104, 104, 104, 0.75);
    }
    .aboutServices{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 20px 5% 20px 5%;
    }
    .aboutServices>div{
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-bottom: 25px;
    }
    .aboutServices > div > h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 14px;
        letter-spacing: 1Px;
        color: #f57c00;
    }
    .aboutServices > div > p {
        font-family: 'Poppins';
        font-size: 12px;
        line-height: 24px;
    }
    .aboutServicePic{
        width: 100px;
        border-radius: 50%;
        box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.415);
    }
    
    .aboutContain2 > div{
        margin-top: 20px;
        width: 90vw;
        margin-left: 2.5vw;
        background-color: #f57b0037;
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    .aboutContain2 > div > h3 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 18px;
        letter-spacing: 1Px;
        color: #02539F;
        margin-top: -22px;
        background-color: #f57b0037;
    }
    .aboutContain2 > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
    }
    .aboutDriverPic{
    
    margin-top: -10px;
        width: 65vw;
        margin-left: 33vw;
        border-radius:4px;
    }
}