.service {
  width: 100vw;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 120px;
  animation: load 1s ease-in-out;
  overflow-x: hidden;
}
.couverturePic {
  width: 100vw;
}
.service > h2 {
  width: 80vw;
  margin-left: 10vw;
  text-transform: uppercase;
  font-family: "Poppins";
  font-size: 22px;
  letter-spacing: 1px;
  color: #02539f;
  margin-top: 90px;
}

.service > p {
  width: 80vw;
  margin-left: 10vw;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.serviceDetails {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 80vw;
  margin-left: calc(10vw - 25px);
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 25px;
}
.serviceDetails > div > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.15);
  width: 20vw;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.8s;
  text-decoration: none;
}
.serviceDetails > div > a:hover {
  box-shadow: 0px 2px 8px 1px rgba(104, 104, 104, 0.4);
}
.serviceDetails > div > a > span {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  padding: 8px;
  color: #f57c00;
}
.serviceDetailPic {
  width: 100%;
  height: 180px;
  border-radius: 4px 4px 0px 0px;
}
@media screen and (max-width: 1200px) {
  .service {
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .couverturePic {
    width: 100vw;
  }
  .service > h2 {
    width: 90vw;
    margin-left: 5vw;
    text-transform: uppercase;
    font-family: "Poppins";
    font-size: 20px;
    letter-spacing: 1px;
    color: #02539f;
    margin-top: 60px;
  }

  .service > p {
    width: 80vw;
    margin-left: 10vw;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
  .serviceDetails {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 90vw;
    margin-left: 3.3vw;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 15px;
  }
  .serviceDetails > div > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.15);
    width: 30vw;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.8s;
    text-decoration: none;
  }
  .serviceDetails > div > a:hover {
    box-shadow: 0px 2px 8px 1px rgba(104, 104, 104, 0.4);
  }
  .serviceDetails > div > a > span {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    padding: 8px;
    color: #f57c00;
  }
  .serviceDetailPic {
    width: 100%;
    height: 180px;
    border-radius: 4px 4px 0px 0px;
  }
}
@media screen and (max-width: 700px) {
  .service {
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .couverturePic {
    width: 100vw;
  }
  .service > h2 {
    width: 95vw;
    margin-left: 2.5vw;
    text-transform: uppercase;
    font-family: "Poppins";
    font-size: 16px;
    letter-spacing: 1px;
    color: #02539f;
    margin-top: 60px;
  }

  .service > p {
    width: 90vw;
    margin-left: 5vw;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
  }
  .serviceDetails {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 90vw;
    margin-left: 3.3vw;
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
  }
  .serviceDetails > div > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.35);
    width: 45vw;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.8s;
    text-decoration: none;
  }
  .serviceDetails > div > a:hover {
    box-shadow: 0px 2px 8px 1px rgba(104, 104, 104, 0.5);
  }
  .serviceDetails > div > a > span {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    padding: 4px;
    color: #f57c00;
  }
  .serviceDetailPic {
    width: 100%;
    height: 120px;
    border-radius: 4px 4px 0px 0px;
  }
}
