.footer{
    height: auto;
    margin-top: 150px;
    width: 80vw;
    margin-left: 10vw;
    display: flex;
    flex-direction: column;
}
.zoneService{
   background-color: #02539F;
   color: white;
   border-radius: 4px;
   padding-bottom: 10px;
}
.zoneService>h2{
   font-family: 'Oswald';
   text-transform: uppercase;
   letter-spacing: 1px;
   color: white;
   margin-bottom: 40px;
   padding-top: -10px;
 }
.zoneService>ul{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    text-align: left;
    justify-content: center;
    column-gap: 100px;
    row-gap: 20px;
    font-family: 'Poppins';
}
hr{
    width: 80vw;
    border-top: 1px solid #02539F;
}
.foots{
    display: flex;
    flex-direction: row;
}
.footer-logo{
    width: 200px;
    border-radius: 4px;
}
.foot{
    width: 40vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.lastFoot{
    width: 40vw;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.foot>h4{
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Oswald';
    color: #02539F;
}
.foot>p{
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Poppins';
    text-align: center;
    color:#474D53 ;
}
.lastFoot>h5{
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Oswald';
}
.privacy{
   font-family: 'Oswald';
   color: black;
   font-weight: 600;
}
.footer-link{
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-family: 'Oswald';
    font-weight: 600;
    letter-spacing: 1px;
    color:#474D53 ;

}
@media screen and (max-width:1200px) {
    .footer{
        height: auto;
        margin-top: 150px;
        width: 95vw;
        margin-left:2.5vw;
        display: flex;
        flex-direction: column;
    }
    .footer-logo{
        width: 120px;
        border-radius: 4px;
    }
     .zoneService>h2{
        font-family: 'Oswald';
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: white;
        margin-bottom: 40px;
        padding-top: -10px;
      }
     .zoneService>ul{
         display: grid;
         grid-template-columns: auto auto auto auto  ;
         text-align: left;
         justify-content: center;
         column-gap: 50px;
         row-gap: 20px;
         font-family: 'Poppins';
     }
}
@media screen and (max-width:700px) {
    .footer{
        height: auto;
        margin-top: 100px;
        width: 90vw;
        margin-left: 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .zoneService{
        background-color: #02539F;
        color: white;
        border-radius: 4px;
        padding-bottom: 10px;
        width: 95vw;
        padding: 4px;
     }
    .zoneService>h2{
        font-family: 'Oswald';
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: white;
        margin-bottom: 40px;
        padding-top: -10px;
      }
     .zoneService>ul{
         display: grid;
         grid-template-columns: auto auto auto  ;
         text-align: left;
         justify-content: center;
         column-gap: 30px;
         row-gap: 20px;
         font-family: 'Poppins';
         font-size: 14px;
         padding: 4px;
     }
    hr{
        width: 90vw;
        margin-left: 0vw;
        border-top: 1px solid rgba(0, 0, 0, 0.375);
    }
    .foots{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-logo{
        width: 150px;
    }
    .foot{
        width: 90vw;
        height: auto;
        margin-left: 0vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .foot>h4{
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .foot>h5{
        font-size: 13px;
    }
    .lastFoot{
        height: 10vh;
        width: 90vw;
    }
    .lastFoot>h5{
        font-size: 10px;
    }
    .foot>p{
        font-size: 11px;
    }
    .footer-link{
        text-decoration: none;
        margin-top: 10px;
        margin-bottom: 20px;
        color: black;
        font-size: 12px;
        font-family: 'Code';
        font-weight: 600;
        letter-spacing: 1px;

    }
}
