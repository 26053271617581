
.contactForm{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 600px;
flex-shrink: 0;
border-radius: 12px;
border: 4px solid #FFF;
background: #FFF;
box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
margin:10px 30px;
justify-content: space-around;
padding: 6px;
}

.ContactTitle{
    margin-top: 20px;
    margin-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-start;
    font-family: 'Poppins';
}
.contactBefore{
    width: 90px;
    height: 4px;
    background-color:#02539F;
    margin-left: -40px;
}
.ContactSpan{
    color: #02539F;
    text-transform: uppercase;
    font-size: 22px;
    margin-left: 20px;
    font-weight: 600;
}
.contactDetails{
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.50);
}
.feedback-input {
    color: rgba(0, 0, 0, 0.50);
	font-family: 'Poppins';
  font-weight:300;
	font-size: 14px;
    margin-left: 40px;
	border-radius: 0;
	line-height: 22px;
	background-color: rgba(255, 255, 255, 0.637);
	padding: 13px 13px 13px 13px;
	margin-bottom: 10px;
	width:60%;
	box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom: 1px solid #16213e83;
}
.custom-slider{
    width: 95vw;
    margin-left: 2.5vw;
    margin-top: 50px;
}
.bookPic{
    width: 20vw;
    border-radius: 4px;
}
.contact-link.book{
    font-size: 28px;
}
@media only screen and (max-width: 1200px) {
    .contactForm{
        display: flex;
        flex-direction: column;
        width: 450px;
        height: 600px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 4px solid #FFF;
    background: #FFF;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
    margin:10px 0px;
    justify-content: space-around;
    padding: 6px;
    }
    .ContactTitle{
        margin-top: 20px;
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:flex-start;
        font-family: 'Poppins';
    }
    .contactBefore{
        width: 90px;
        height: 6px;
        background-color:#02539F;
        margin-left: -40px;
    }
    .ContactSpan{
        color: #02539F;
        text-transform: uppercase;
        font-size: 28px;
        margin-left: 20px;
        font-weight: 600;
    }
    .contactDetails{
        margin-left: 10px;
        color: rgba(0, 0, 0, 0.50);
    }
    .feedback-input {
        color: rgba(0, 0, 0, 0.50);
        font-family: 'Poppins';
      font-weight:300;
        font-size: 14px;
        margin-left: 40px;
        border-radius: 0;
        line-height: 22px;
        background-color: rgba(255, 255, 255, 0.637);
        padding: 13px 13px 13px 13px;
        margin-bottom: 10px;
        width:60%;
        box-sizing: border-box;
      border: 0px solid transparent;
      border-bottom: 1px solid #16213e83;
    }
    .custom-slider{
        width: 95vw;
        margin-left: 2.5vw;
        margin-top: 50px;
    }
    .bookPic{
        width: 30vw;
        border-radius: 4px;
    }
    .contact-link.book{
        font-size: 24px;
    }
}
@media only screen and (max-width: 700px) {
    .contactForm{
        
        width: 85vw;
        height: 600px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #FFF;
    background: #FFF;
    box-shadow: 0px 1px 6px 1px rgba(189, 189, 189, 0.25);
    margin:10px 0px;
    justify-content: space-around;
    padding: 4px;
    }
    .ContactTitle{
        margin-top: 20px;
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:flex-start;
        font-family: 'Poppins';
    }
    .contactBefore{
        width: 60px;
        height: 4px;
        background-color:#02539F;
        margin-left: -40px;
    }
    .ContactSpan{
        color: #02539F;
        text-transform: uppercase;
        font-size: 20px;
        margin-left: 20px;
        font-weight: 600;
    }
    .contactDetails{
        margin-left: 10px;
        color: rgba(0, 0, 0, 0.50);
        font-size: 14px;
    }
    .feedback-input {
        color: rgba(0, 0, 0, 0.50);
        font-family: 'Poppins';
      font-weight:300;
        font-size: 12px;
        margin-left: 40px;
        border-radius: 0;
        line-height: 22px;
        background-color: rgba(255, 255, 255, 0.637);
        padding: 13px 13px 13px 13px;
        margin-bottom: 10px;
        width:80%;
        box-sizing: border-box;
      border: 0px solid transparent;
      border-bottom: 1px solid #16213e83;
    }
    .custom-slider{
        width: 95vw;
        margin-left: 2.5vw;
        margin-top: 50px;
    }
    .bookPic{
        width: 45vw;
        border-radius: 4px;
    }

    
    }