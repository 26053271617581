.home{
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 150px;
    animation: load 1s ease-in-out; 
    overflow-x: hidden;
}
.homeTop{
    width: 97vw;
    margin-left: 1vw;
    border: 1px white solid;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#02539F ;
    box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.75);
    margin-bottom: 10px;
}
.homeTop>div{
    width: 40vw;
    height: 100%;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: azure;
}
.homeTop > div > h3 {
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 22px;
    letter-spacing: 2Px;
}
.homeTop > div > h4 {
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 18px;
}.homeTop > div > p {
    font-family: 'Poppins';
    font-size: 16px;
    padding: 2px;
}
.homeMiddle{
    margin-top: 150px;
    width: 80vw;
    margin-left: 10vw;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.homeMiddle > div{
    width: 50vw;
    margin-left: 5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.homeMiddle > div > h4 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 22px;
    letter-spacing: 2Px;
    color: #02539F;
}
.homeMiddle > div > p {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 28px;
    padding: 2px;
}
.homeMiddle2{
    margin-top: 150px;
    width: 80vw;
    margin-left: 10vw;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.homeMiddle2 >.homeMiddle22 {
border-radius: 4px;
    background-color: #f57b0037;
}
.homeMiddle2 >.homeMiddle22> h4 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2Px;
    color: #02539F;
    padding: 4px;
    background-color: #f57b0037;
    margin-top: -25px;
    border-radius: 4px;
}
.homeMiddle2>.homeMiddle22 > p {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 28px;
    padding: 4px;
}
.homeMiddle3{
    margin-top: 150px;
    width: 80vw;
    margin-left: 10vw;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.homeMiddle3 > div{
    width: 50vw;
    margin-right: 5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.homeMiddle3 > div > h4 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 20px;
    letter-spacing: 1Px;
    color: #02539F;
}
.homeMiddle3 > div > p {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 28px;
}
.homePic1{
    width: 60vw;
    border-radius: 4px;
}
.homePic2{
    width: 40vw;
    border-radius: 4px;
    box-shadow: 1px 1px 8px 1px rgba(104, 104, 104, 0.75);
}
.serviceBlock{
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}
.servicePic{
    width: 300px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.servicePic:hover{
   transform: translateY(-15px)
}
@media screen and (max-width:1200px) {
    .home{
        top: 100px;
        animation: load 1s ease-in-out; 
        overflow-x: hidden;
    }
    .homeTop{
        width: 98vw;
        margin-left: 1vw;
        border: 1px white solid;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color:#02539F ;
        box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.75);
        margin-bottom: 10px;
    }
    .homeTop>div{
        width: 100%;
        height: 100%;
        padding: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: azure;
    }
    .homeTop > div > h3 {
        text-transform: uppercase;
        font-family: 'Poppins';
        font-size: 20px;
        letter-spacing: 2Px;
    }
    .homeTop > div > h4 {
        text-transform: uppercase;
        font-family: 'Poppins';
        font-size: 16px;
    }.homeTop > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        
    }
    .homeMiddle{
        margin-top: 150px;
        width: 90vw;
        margin-left: 5vw;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }
    .homeMiddle > div{
        width: 90vw;
        margin-left: 5vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .homeMiddle > div > h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 18px;
        letter-spacing: 1Px;
        color: #02539F;
    }
    .homeMiddle > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
    }
    .homeMiddle2{
        margin-top: 100px;
        width: 90vw;
        margin-left: 5vw;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }
    .homeMiddle2 >.homeMiddle22 {
    border-radius: 4px;
        background-color: #f57b0037;
    }
    .homeMiddle2 >.homeMiddle22> h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 2Px;
        color: #02539F;
        padding: 4px;
        background-color: #f57b0037;
        margin-top: -20px;
        border-radius: 4px;
    }
    .homeMiddle2>.homeMiddle22 > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
        padding: 4px;
    }
    .homeMiddle3{
        margin-top: 150px;
        width: 95vw;
        margin-left: 2.5vw;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }
    .homeMiddle3 > div{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .homeMiddle3 > div > h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 18px;
        letter-spacing: 1Px;
        color: #02539F;
    }
    .homeMiddle3 > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
    }
    .homePic1{
        width: 100%;
        border-radius: 4px 4px 0px 0px;
    }
    .homePic2{
        width: 40vw;
        border-radius: 4px;
        box-shadow: 1px 1px 8px 1px rgba(104, 104, 104, 0.75);
    }
    .serviceBlock{
        width: 95vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;
    }
    .servicePic{
        width: 200px;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }
    .servicePic:hover{
       transform: translateY(-15px)
    }

}
@media screen and (max-width:700px) {
    .home{
        top: 80px;
        animation: load 1s ease-in-out; 
        overflow-x: hidden;
    }
    .homeTop{
        width: 100vw;
        margin-left: 0vw;
        border: 0px white solid;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 4px;
    }
    .homeTop>div{
        width: 100%;
        height: 100%;
        padding: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: azure;
    }
    .homeTop > div > h3 {
        text-transform: uppercase;
        font-family: 'Poppins';
        font-size: 18px;
        letter-spacing: 1Px;
    }
    .homeTop > div > h4 {
        text-transform: uppercase;
        font-family: 'Poppins';
        font-size: 16px;
    }.homeTop > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        padding:0px 2px;
        
    }
    .homeMiddle{
        margin-top: 10px;
        width: 90vw;
        margin-left: 5vw;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }
    .homeMiddle > div{
        width: 90vw;
        margin-left: 0vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .homeMiddle > div > h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 18px;
        letter-spacing: 1Px;
        color: #02539F;
    }
    .homeMiddle > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
    }
    .homeMiddle2{
        margin-top: 100px;
        width: 90vw;
        margin-left: 5vw;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }
    .homeMiddle2 >.homeMiddle22 {
    border-radius: 4px;
        background-color: #f57b0037;
    }
    .homeMiddle2 >.homeMiddle22> h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1Px;
        color: #02539F;
        padding: 2px;
        background-color: #f57b0037;
        margin-top: -15px;
        border-radius: 4px;
    }
    .homeMiddle2>.homeMiddle22 > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
        padding: 2px;
    }
    .homeMiddle3{
        margin-top: 50px;
        width: 95vw;
        margin-left: 2.5vw;
        border-radius: 4px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 10px;
    }
    .homeMiddle3 > div{
        width: 95vw;
        margin-left: 2.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .homeMiddle3 > div > h4 {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 16px;
        letter-spacing: 1Px;
        color: #02539F;
    }
    .homeMiddle3 > div > p {
        font-family: 'Poppins';
        font-size: 14px;
        line-height: 26px;
    }
    .homePic1{
        width: 100%;
        border-radius: 4px 4px 0px 0px;
    }
    .homePic2{
        width: 80vw;
        margin: 20px 0px;
        border-radius: 4px;
        box-shadow: 1px 1px 8px 1px rgba(104, 104, 104, 0.75);
    }
    .serviceBlock{
        width: 95vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 25px;
    }
    .servicePic{
        width: 250px;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }
    .servicePic:hover{
       transform: translateY(-15px)
    }

}