.phoneBtn{
  display: inline-block;
    position: fixed;
    bottom: 80px;
    right: 10px;
    z-index: 999999999;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    animation: floatx 0.8s linear infinite;
   background-color: #00c548;
  }

  
  @keyframes floatx {
    0% {
      transform:  rotate(0deg) ;
  }
  25% {
      transform:  rotate(5deg) ;
  }
  50% {
      transform:  rotate(-5deg) ;
  }
  75% {
      transform:  rotate(5deg) ;
  }
  100% {
      transform:  rotate(0deg) ;
  }
  }
  
  @media screen and (max-width:700px) {
    .topBtn{
      right: 2vw;
    }
  }