.contact{
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 120px;
    animation: load 1s ease-in-out; 
    overflow-x: hidden;
}
.locationTitle{
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 22px;
    letter-spacing: 1Px;
    color: #02539F;
    margin-top: 100px;
}
.contactDiv{
    margin-top: 150px;
    width: 80vw;
    margin-left: 10vw;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.contactDiv > div{
    width: 60vw;
    margin-left: 5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.contactDiv > div > h3 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 20px;
    letter-spacing: 1Px;
    color: #02539F;
}
.contactDiv > div > span {
    font-family: 'Poppins';
    margin-top: 10px;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactDiv > div > span >a{
text-decoration: none;
color: black;
transition: all 0.7s;
}
.contactDiv > div > span >a:hover{
    color: #02539F;
    }
    .map{
        width: 600px;
        height: 400px;
    }
    .contact>h3 {
        width: 80vw;
        margin-left: 10vw;
        text-transform: uppercase;
        font-family: 'Poppins';
        font-size: 22px;
        letter-spacing: 1Px;
        color: #02539F;
        margin-top: 90px;
    }
    .contact > p {
        width: 80vw;
        margin-left: 10vw;
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
    }
    @media screen and (max-width: 1200px) {
        .contact{
            width: 100vw;
            height: auto;
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 80px;
            animation: load 1s ease-in-out; 
            overflow-x: hidden;
        }
        .locationTitle{
            text-transform: uppercase;
            font-family: 'Poppins';
            font-size: 20px;
            width: 95vw;
            letter-spacing: 1Px;
            color: #02539F;
            margin-top: 80px;
        }
        .contactDiv{
            margin-top: 100px;
            width: 90vw;
            margin-left: 5vw;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;
        }
        .contactDiv > div{
            width: 100vw;
            margin-left: 5vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
        .contactDiv > div > h3 {
            text-transform: uppercase;
            font-family: 'Oswald';
            font-size: 18px;
            letter-spacing: 1Px;
            color: #02539F;
        }
        .contactDiv > div > span {
            font-family: 'Poppins';
            margin-top: 10px;
            font-size: 16px;
            line-height: 26px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .contactDiv > div > span >a{
        text-decoration: none;
        color: black;
        transition: all 0.7s;
        }
        .contactDiv > div > span >a:hover{
            color: #02539F;
            }
            .map{
                width: 900px;
                height: 400px;
            }
            .contact>h3 {
                width: 80vw;
                margin-left: 10vw;
                text-transform: uppercase;
                font-family: 'Poppins';
                font-size: 22px;
                letter-spacing: 1Px;
                color: #02539F;
                margin-top: 90px;
            }
            .contact > p {
                width: 80vw;
                margin-left: 10vw;
                font-family: 'Poppins';
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }
    }
    @media screen and (max-width: 1200px) {
    
        .locationTitle{
            text-transform: uppercase;
            font-family: 'Poppins';
            font-size: 16px;
            width: 95vw;
            margin-left: 2.5vw;
            letter-spacing: 1Px;
            color: #02539F;
            margin-top: 60px;
        }
        .contactDiv{
            margin-top: 50px;
            width: 90vw;
            margin-left: 5vw;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;
        }
        .contactDiv > div{
            width: 100vw;
            margin-left: 0vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
        .contactDiv > div > h3 {
            text-transform: uppercase;
            font-family: 'Oswald';
            font-size: 18px;
            letter-spacing: 1Px;
            color: #02539F;
        }
        .contactDiv > div > span {
            font-family: 'Poppins';
            margin-top: 10px;
            font-size: 16px;
            line-height: 26px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .contactDiv > div > span >a{
        text-decoration: none;
        color: black;
        transition: all 0.7s;
        }
        .contactDiv > div > span >a:hover{
            color: #02539F;
            }
            .map{
                width: 600px;
                height: 400px;
            }
            .contact>h3 {
                width: 80vw;
                margin-left: 10vw;
                text-transform: uppercase;
                font-family: 'Poppins';
                font-size: 22px;
                letter-spacing: 1Px;
                color: #02539F;
                margin-top: 90px;
            }
            .contact > p {
                width: 80vw;
                margin-left: 10vw;
                font-family: 'Poppins';
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }
    }
    @media screen and (max-width: 700px) {
    
        .locationTitle{
            text-transform: uppercase;
            font-family: 'Poppins';
            font-size: 16px;
            width: 95vw;
            margin-left: 2.5vw;
            letter-spacing: 1Px;
            color: #02539F;
            margin-top: 60px;
        }
        .contactDiv{
            margin-top: 50px;
            width: 90vw;
            margin-left: 5vw;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;
        }
        .contactDiv > div{
            width: 100vw;
            margin-left: 0vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
        .contactDiv > div > h3 {
            text-transform: uppercase;
            font-family: 'Oswald';
            font-size: 18px;
            letter-spacing: 1Px;
            color: #02539F;
        }
        .contactDiv > div > span {
            font-family: 'Poppins';
            margin-top: 10px;
            font-size: 16px;
            line-height: 26px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .contactDiv > div > span >a{
        text-decoration: none;
        color: black;
        transition: all 0.7s;
        }
        .contactDiv > div > span >a:hover{
            color: #02539F;
            }
            .map{
                width: 90vw;
                height: 200px;
            }
            .contact>h3 {
                width: 90vw;
                margin-left: 5vw;
                text-transform: uppercase;
                font-family: 'Poppins';
                font-size: 18px;
                letter-spacing: 1Px;
                color: #02539F;
                margin-top: 90px;
            }
            .contact > p {
                width: 80vw;
                margin-left: 10vw;
                font-family: 'Poppins';
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }
    }